// ** React Imports
import { Suspense, lazy } from "react"
import { createRoot, ReactDOM } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
// moment js
import moment from 'moment'
// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"
// ** ThemeColors Context
import ability from './configs/acl/ability' // subMenu
import { AbilityContext } from './utility/context/Can'  // subMenu
import { ThemeContext } from "./utility/context/ThemeColors"
// ** ThemeConfig
import themeConfig from "./configs/themeConfig"
// ** i18n
import './configs/i18n'
// ** Toast
import { Toaster } from "react-hot-toast"
// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"
// ** Ripple Button
import "./@core/components/ripple-button"
// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"
// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"
// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"
import "flag-icons/css/flag-icons.min.css";
// swiper css
// import "swiper/swiper-bundle.css";
import 'swiper/css';
import 'swiper/css/pagination';
// react-image-crop css
import 'react-image-crop/dist/ReactCrop.css'
//react-slick css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// datepicker css
import "react-datepicker/dist/react-datepicker.css";
// reactPrime css
import 'primereact/resources/primereact.min.css';
// skeleton css
import 'react-loading-skeleton/dist/skeleton.css'
// ** Core styles
import "./@core/scss/base/fonts.scss"
import "./@core/scss/core.scss"
import "./@core/assets/css/flatpickr.min.css"
import "./@core/assets/css/style.css"
// ** Service Worker
import * as serviceWorker from "./serviceWorker"
// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <LazyApp />
          <Toaster
            position={themeConfig.layout.toastPosition}
            toastOptions={{ className: "react-hot-toast" }}
          />
        </ThemeContext>
      </AbilityContext.Provider>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
          // if (window.confirm("There is a new version of the app ready. Please reload to update.")) {
          //   window.location.reload()
          // }
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  }
})
