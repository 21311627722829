import logoImage from '../../../src/@core/assets/images/logo/logoAdignis.webp'

export default {
    labels: {
        //  appName
        appName: "Adignis",
        // loginPage
        welcome: 'Welcome to Adignis',
        menuName: 'Adignis Panel',
        menuLogo: logoImage,
        footerName: 'Adignis'
    },

    strings: {
        image_description: " Note : Please upload Image less then 2 MB and above 800px resolution.",
        sure_active: "Are you sure you want to activate this user?",
    },

    validations: {
        ice_breaker_message: "Please enter message",
    }
}